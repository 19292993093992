<template>
  <div class="home">
    <div class="container">
      <div class="main">
        <div class="sidebar">
          <div class="card-list" v-if="projectList.length > 0">
            <div class="card" :class="{ active: item.id == $route.params.id && !item.special }" v-for="item in projectList" :key="item.name" @click="toDetail(item)">
              <div class="card-header">{{ getText(item, 'name') }}</div>
              <div class="card-content">
                <el-row type="flex" justify="space-between">
                  <el-col :span="12">
                    <div class="card-meta">
                      <span class="card-label">{{ $t('fund.DeployedCapital') }}：</span>
                      <span class="card-value">{{ item.totalInvest }}</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="card-meta">
                      <span class="card-label">{{ $t('fund.ClosingDate') }}：</span>
                      <span class="card-value">{{ item.establish }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="content" v-if="fund">
          <div class="card">
            <div class="card-header">{{ getText(fund, 'name') }}</div>
            <div class="card-content">
              <div class="deadline" v-if="fund.deadlineDate">{{ $t('DeadLine') }} {{ fund.deadlineDate }}</div>
              <el-row>
                <el-col :span="8">{{ $t('fund.fundSize') }}：{{ getText(fund, 'scale') }}</el-col>
                <el-col :span="8">{{ $t('fund.ClosingDate') }}：{{ fund.establish }}</el-col>
                <el-col :span="8">{{ $t('fund.FundTerm') }}：{{ getText(fund, 'years') }}</el-col>
                <el-col :span="8">{{ $t('fund.registeredLocation') }}：{{ getText(fund, 'registerAddress') }}</el-col>
                <el-col :span="8">{{ $t('fund.DeployedCapital') }}：{{ fund.totalInvest }}</el-col>
                <el-col :span="8">{{ $t('fund.TotalDistribution') }}：{{ getText(fund, 'totalDispense') }}</el-col>
                <el-col :span="8">{{ $t('fund.portfoliosNumber') }}：{{ fund.projectNumber }}</el-col>
                <el-col :span="8" v-if="fund.moic">MOIC：{{ fund.moic }}</el-col>
                <!-- <el-col :span="8" v-if="fund.marketTo">{{ $t('MarkedtoMarket') }}：{{ getText(fund, 'marketTo') }}</el-col> -->
              </el-row>
            </div>
          </div>
          <el-row>
            <el-button type="primary" style="margin-right: 16px" @click="$router.push(`/projects/${fund.id}`)">{{ $t('fund.FundPortfolio') }}</el-button>
            <el-popover placement="bottom" width="314" trigger="click" v-if="lpReportFileList.length > 0">
              <div class="download-list">
                <ul>
                  <li v-for="report in lpReportFileList" :key="report.name">
                    <a :href="report.fileUrl" target="_blank" download>
                      <div class="name">{{ getText(report, 'name') }}</div>
                    </a>
                  </li>
                </ul>
              </div>
              <el-button type="primary" slot="reference">{{ $t('fund.lpReport') }}</el-button>
            </el-popover>
          </el-row>
          <div class="chart-list" v-if="chartList.length > 0">
            <el-row :gutter="16">
              <el-col :span="24" v-for="(chart, chartIndex) in chartList" :key="'chart' + chartIndex">
                <div class="card">
                  <div class="card-header">{{ getText(chart, 'name') }}</div>
                  <div class="card-content">
                    <EchartLine :chart-info="chart" :key="fund.id" v-if="chart.chartType == 'LINE'" />
                    <EchartBar :chart-info="chart" :key="fund.id" v-if="chart.chartType == 'BAR'" />
                    <EchartPie :chart-info="chart" :key="fund.id" v-if="chart.chartType == 'PIE'" />
                    <EchartMixLineBar :chart-info="chart" :key="fund.id" v-if="chart.chartType == 'LINE_AND_BAR'" />
                    <EchartBarStack :chart-info="chart" :key="fund.id" v-if="chart.chartType == 'MULTI_BAR'" />
                  </div>
                  <div class="card-footer" :style="chart.remark ? '' : 'background: #F7F8FA;'">
                    <div class="tips">
                      <template v-if="chart.remark">{{ $t('remark') }}：{{ getText(chart, 'remark') }}</template>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

import EchartLine from '@/components/echarts/Line.vue'
import EchartPie from '@/components/echarts/Pie.vue'
import EchartBar from '@/components/echarts/Bar.vue'
import EchartBarStack from '@/components/echarts/BarStack.vue'
import EchartMixLineBar from '@/components/echarts/MixLineBar.vue'

export default {
  name: 'Home',
  components: {
    EchartLine,
    EchartPie,
    EchartBar,
    EchartBarStack,
    EchartMixLineBar,
  },
  data() {
    return {
      id: null,
      projectList: [],
      fund: null,
      lpReportFileList: [],
      chartList: [],
    }
  },
  watch: {
    $route() {
      this.purchasedFundAndProjectList()
      this.getFund()
    },
  },
  created() {
    this.purchasedFundAndProjectList()
    this.getFund()
  },
  methods: {
    purchasedFundAndProjectList() {
      api.purchasedFundAndProjectList().then((res) => {
        this.projectList = res.data
      })
    },
    getFund() {
      api.getFund(this.$route.params.id).then((res) => {
        const { lpReportFileList, chartList } = res.data
        this.fund = res.data
        this.lpReportFileList = lpReportFileList
        this.chartList = chartList
      })
    },
    toDetail(obj) {
      if (obj.special) {
        this.$router.push(`/project/${obj.id}`)
      } else {
        this.$router.push(`/fund/${obj.id}`)
      }
    },
  },
}
</script>
